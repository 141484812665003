
    @import "config.scss";
 
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 25px;
  z-index: 5;
}

@include for-desktop-up {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    position: relative;
    padding: 10px;
    gap: 10px;

    .image {
      z-index: -1;
    }
  }
}

.streamerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 25px;
  z-index: 5;
}
